import React from 'react';
import styled from 'styled-components';
import { useStaticQuery } from 'gatsby';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  IntegrationScreen,
  CompressionScreen,
  InfrastructureScreen,
} from '../../components/forImageCDN';

import { FinalCTA } from '../../components/FinalCTA';
import { ClientsCases } from '../../components/ClientsCases';
import { plansQuery } from '../pricing';

const FALLBACK_PRICE = 79; // Current Pro plan's price

const ImageProcessingClientsCases = styled(ClientsCases)`
  margin-top: 0;
`;

export const frontmatter = {
  title: 'Image CDN',
  description:
    'Uploadcare Smart CDN is a geographically distributed system of servers that delivers content to client devices using the shortest route. It allows you to compress and process images on the fly with the help of our URL API.',
};

const ImageCdn = ({ location }) => {
  const {
    plans: { availablePlans: plans },
  } = useStaticQuery(plansQuery) ?? [];
  const firstPaidPlan = plans.find(({ price }) => Number(price) > 0) ?? {};

  return (
    <SiteLayout meta={frontmatter} pathName={location.pathname}>
      <FirstScreen />
      <CompressionScreen />
      <IntegrationScreen />
      <InfrastructureScreen />
      <ImageProcessingClientsCases />
      <FinalCTA
        heading={`Start for free, grow for as low as $${firstPaidPlan.price || FALLBACK_PRICE}`}
      />
    </SiteLayout>
  );
};

export default ImageCdn;
